const capitalizeFirstLetter = (text) =>
  text && text.toString().charAt(0).toUpperCase() + text.toString().slice(1);

const displayPhoneNumber = (number) => {
  const countryCode = number.slice(0, 4);
  const lastTwoDigits = number.slice(-2);
  const minimumAsterisks = Math.max(0, number.length - 6);
  const asterisks = "*".repeat(minimumAsterisks);
  return `${countryCode}${asterisks}${lastTwoDigits}`;
};

const getCountryName = (val, countries) => {
  const obj = countries.find((element) => element["alpha-2"] === val);
  if (obj) {
    return obj.name;
  }
  return val;
};

export const scrollToTop = () => {
  window.scrollTo({ top: 0, behavior: "smooth" });
};

export const setLanguage = (value) => localStorage.setItem("language", value);
export const getLanguage = () => localStorage.getItem("language");
export const setSavedProduct = (value) =>
  sessionStorage.setItem("productChosen", value);
export const getSavedProduct = () => sessionStorage.getItem("productChosen");
export const setSaveInternationalType = (value) =>
  sessionStorage.setItem("internationalType", value);
export const getSavedInternationalType = () =>
  sessionStorage.getItem("internationalType");

export const formatValue = (value) =>
  new Intl.NumberFormat("en-US", {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  }).format(value);

export { capitalizeFirstLetter, getCountryName, displayPhoneNumber };
