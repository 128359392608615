import DateUtils from "../date";

class ValidationUtil {
  static validateEmail = (email) =>
    /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(email);

  static isEmpty(value) {
    return value !== undefined && String(value).trim().length && value != null
      ? false
      : true;
  }

  static validateExpiryDate(value) {
    return DateUtils.isDateInFuture(value);
  }

  static isAlphaNumericWithSpaces(value) {
    return /^[ a-z0-9]+$/i.test(value);
  }

  static isLengthInRange(value, min, max) {
    // nosemgrep
    const regex = new RegExp(`^.{${min},${max}}$`);
    return regex.test(value);
  }
  static containsNumber(value) {
    return /\d+/.test(value);
  }

  static isNotEnglishCharacters(value) {
    return /[^\x00-\x7F]+/i.test(value);
  }

  static startWithWhiteSpaces(value) {
    return /^\s+/.test(value);
  }

  static containsNameSpecialChars(value) {
    const format = /[!@#$%^&*()_+\=[\]{};:"\\|.<>\/?~`,]/;
    return format.test(value);
  }

  static containsSpecialCharacters(value) {
    const format = /[!@#$%^&*()_+\-=[\]{};`':"\\|,.<>\/?~]/;
    return format.test(value);
  }

  static containsAlphabeticalChars(value) {
    return /[^0-9]/.test(value);
  }

  static isKuwaitiNumber(value) {
    return /^[569]\d{7}$|^(41)\d{6}$/.test(value);
  }

  static containsArabicNumbers(value) {
    return /[\u0660-\u0669]/i.test(value);
  }

  static containsWhiteSpace(value) {
    return /\s/g.test(value);
  }

  static isExactLength(value, length) {
    // nosemgrep
    const regex = new RegExp(`^\\d{${length}}$`);
    return regex.test(value);
  }

  static isExactLengthForCharactersDigit(value, length) {
    const regex = new RegExp(`^.{${length}}$`);
    return regex.test(value);
  }
  static isAlphaNumeric(value) {
    return /^[a-z0-9]+$/i.test(value);
  }
}
export default ValidationUtil;
