import React from "react";
import PropTypes from "prop-types";
import CustomSelect from "../../../widgets/inputs/custom-select";
import { brokerageOptions } from "../../../config";
import { useTranslation } from "react-i18next";

const BrokerageType = ({ data, setData, isDisabled }) => {
  const { t } = useTranslation("common");

  const brokrageOptions = brokerageOptions.map((brokerage) => ({
    value: brokerage.value,
    label: t(brokerage.label),
  }));

  const validate = (value) => {
    setData(value);
  };

  const handleChange = (value) => {
    validate(value);
  };

  return (
    <CustomSelect
      isDisabled={isDisabled}
      name={t("product")}
      label={t("product")}
      onChange={handleChange}
      value={data}
      inputOptions={brokrageOptions}
    />
  );
};

BrokerageType.propTypes = {
  data: PropTypes.string.isRequired,
  setData: PropTypes.func.isRequired,
  isDisabled: PropTypes.bool.isRequired,
};

export default BrokerageType;
