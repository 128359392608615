import React, { useEffect } from "react";
import KYCValidator from "../../../helpers/validation";
import InputField from "../../../widgets/inputs/primary-input";
import KycKeys from "../../../enums/kyc-enum";
import PropTypes from "prop-types";

const SpouseName = ({ data, setKycData, translator }) => {
  const fieldKey = KycKeys.PARTNER_NAME;
  const marriageStatusKey = KycKeys.MARRIAGE_STATUS;

  const {
    [fieldKey]: { value, error, isDisabled },
    [marriageStatusKey]: { value: maritalStatusValue },
  } = data;

  const isVisible = maritalStatusValue;

  const validateField = (value) => {
    const fieldErrorObject = KYCValidator.validateNames(value);
    setKycData((prev) => ({
      ...prev,
      [fieldKey]: {
        ...prev[fieldKey],
        isValidated: true,
        error: fieldErrorObject.errorMessage,
        value,
      },
    }));
  };

  const handleChange = (e) => {
    validateField(e.target.value);
  };

  useEffect(() => {
    setKycData((prev) => ({
      ...prev,
      [fieldKey]: {
        ...prev[fieldKey],
        isVisible: isVisible,
      },
    }));
  }, [isVisible]);

  if (!isVisible) {
    return null;
  }

  return (
    <InputField
      name={"partnerName"}
      label={translator("partnerName")}
      onChange={handleChange}
      value={value}
      errorMessage={error}
      isDisabled={isDisabled}
    />
  );
};

SpouseName.propTypes = {
  data: PropTypes.object.isRequired,
  setKycData: PropTypes.func.isRequired,
  translator: PropTypes.func.isRequired,
};

export default SpouseName;
