import React from "react";
import KYCValidator from "../../../helpers/validation";
import InputField from "../../../widgets/inputs/primary-input";
import KycKeys from "../../../enums/kyc-enum";
import infoIcon from "../../../assets/icons/info-icon.svg";
import PropTypes from "prop-types";
import { capitalizeFirstLetter } from "../../../utils";

const MiddleName = ({ data, setKycData, translator }) => {
  const fieldKey = KycKeys.MIDDLE_NAME;

  const {
    [fieldKey]: { value, error, isDisabled },
  } = data;

  const validateField = (value) => {
    const fieldErrorObject = KYCValidator.validateCustomerName(value);
    setKycData((prev) => ({
      ...prev,
      [fieldKey]: {
        ...prev[fieldKey],
        isValidated: true,
        error: fieldErrorObject.errorMessage,
        value: capitalizeFirstLetter(value),
      },
    }));
  };

  const handleChange = (e) => {
    validateField(e.target.value);
  };

  return (
    <InputField
      name={"middleName"}
      label={translator("middleName")}
      onChange={handleChange}
      value={value}
      errorMessage={error}
      isDisabled={isDisabled}
      tooltipIcon={infoIcon}
      tooltipMessage={translator("middleNameTooltipMessage")}
      inputadornment={"end"}
    />
  );
};

MiddleName.propTypes = {
  data: PropTypes.object.isRequired,
  setKycData: PropTypes.func.isRequired,
  translator: PropTypes.func.isRequired,
};

export default MiddleName;
