import React from "react";
import MainLayout from "../../layouts/main-layout";
import { useTranslation } from "react-i18next";
import styles from "./contact-support.module.scss";

const ContactSupport = () => {
  const { t } = useTranslation("common");

  return (
    <MainLayout title={t("contactSupport")}>
      <div className={styles.contactSupportPage}>
        <div className={styles.supportSection}>
          <span>{t("furtherInformationSupport")}</span>
          <a className={styles.phoneNumber} href="tel:1803111">
            {t("internalSupportNumber")}
          </a>
        </div>
        <div className={styles.supportSection}>
          <span>{t("supportOutsideKuwait")}</span>
          <a className={styles.phoneNumber} href="tel:+96522246903">
            {t("supportNumberOutisdeKuwait")}
          </a>
        </div>
        <div className={styles.supportSection}>
          <p>{t("workingHours")}</p>
        </div>
      </div>
    </MainLayout>
  );
};

export default ContactSupport;
